export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191')
];

export const server_loads = [0];

export const dictionary = {
		"/(private)": [50,[5]],
		"/(private)/advertisers/email-message": [51,[5,7]],
		"/(private)/advertisers/list": [52,[5,6,8]],
		"/(private)/advertisers/list/create": [54,[5,6,8]],
		"/(private)/advertisers/list/[revenueOriginId]": [53,[5,6,8]],
		"/(private)/advertisers/open-balance": [55,[5,6]],
		"/(private)/advertisers/settled-balance": [56,[5,6]],
		"/(private)/affiliate-managers": [63,[5,12]],
		"/(private)/affiliate-managers/invite": [65,[5,12]],
		"/(private)/affiliate-managers/[accountId]": [64,[5,12]],
		"/(private)/affiliate/detail/[accountId]": [57,[5,9]],
		"/(private)/affiliate/email-message": [58,[5,10]],
		"/(private)/affiliate/list": [59,[5,9,11]],
		"/(private)/affiliate/list/create": [61,[5,9,11]],
		"/(private)/affiliate/list/import": [62,[5,9,11]],
		"/(private)/affiliate/list/[accountId]": [60,[5,9,11]],
		"/(private)/billing-details": [72,[5,16]],
		"/(private)/billing/open-balance": [66,[5,13]],
		"/(private)/billing/payment-methods": [67,[5,13]],
		"/(private)/billing/settled-balance": [68,[5,13,14]],
		"/(private)/billing/settled-balance/[settlementId]": [69,[5,13,14,15]],
		"/(private)/billing/settled-balance/[settlementId]/export": [70,[5,13,14,15]],
		"/(private)/billing/settled-balance/[settlementId]/invoice/[accountSettlementId]": [71,[5,13,14,15]],
		"/(private)/coupons/list": [73,[5,17]],
		"/(private)/coupons/list/import": [74,[5,17]],
		"/(private)/coupons/list/new": [75,[5,17]],
		"/(private)/create-offer/[offerId]/creatives": [76,[5,18]],
		"/(private)/create-offer/[offerId]/general": [77,[5,18]],
		"/(private)/create-offer/[offerId]/landing-pages": [78,[5,18]],
		"/(private)/create-offer/[offerId]/launch": [79,[5,18]],
		"/(private)/create-offer/[offerId]/revenue-payouts": [80,[5,18]],
		"/(private)/dashboard": [81,[5]],
		"/(private)/environment/[...path]": [82,[5]],
		"/(auth)/forgot-password": [46,[2]],
		"/(auth)/google-login-redirect": [47],
		"/(private)/integrations": [83,[5,19]],
		"/(private)/integrations/help": [85,[5,19]],
		"/(private)/integrations/[trackingMethod]": [84,[5,19]],
		"/(invite)/invite": [49,[4]],
		"/(private)/logs/activity": [86,[5,21]],
		"/(private)/logs/activity/[id]": [87,[5,21]],
		"/(private)/logs/conversion": [88,[5,22]],
		"/(private)/logs/conversion/[requestId]": [89,[5,22]],
		"/(private)/logs/postbacks": [90,[5,20,23]],
		"/(private)/logs/postbacks/[postbackId]": [91,[5,20,23]],
		"/(private)/marketplace": [92,[5]],
		"/(private)/marketplace/managers": [93,[5,24]],
		"/(private)/marketplace/managers/[slug]": [94,[5,24,25]],
		"/(private)/offers/applications": [111,[5,29]],
		"/(private)/offers/applications/[applicationId]": [112,[5,29]],
		"/(private)/offers/list/[status]": [113,[5,30]],
		"/(private)/offers/list/[status]/create": [114,[5,30]],
		"/(private)/offers/list/[status]/create/(offerGuide)/advertiser": [115,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/complete": [116,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/currency": [117,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/landing-page": [118,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/name": [119,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/non-transactional": [120,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/optional-payout": [121,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/payout-type": [122,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/recurring": [123,[5,30,31]],
		"/(private)/offers/list/[status]/create/(offerGuide)/set-payout": [124,[5,30,31]],
		"/(private)/offers/payout-builder": [125,[5,32]],
		"/(private)/offers/payout-builder/create": [127,[5,32]],
		"/(private)/offers/payout-builder/[adjustmentId]": [126,[5,32]],
		"/(private)/offers/smart-links": [128,[5,33]],
		"/(private)/offers/smart-links/create": [130,[5,33]],
		"/(private)/offers/smart-links/get-link/[smartLinkId]": [131,[5,33]],
		"/(private)/offers/smart-links/[smartLinkId]": [129,[5,33]],
		"/(private)/offers/[offerId]/affiliate-tracking": [95,[5,26]],
		"/(private)/offers/[offerId]/caps": [96,[5,26,27]],
		"/(private)/offers/[offerId]/caps/new": [98,[5,26,27]],
		"/(private)/offers/[offerId]/caps/[capId]": [97,[5,26,27]],
		"/(private)/offers/[offerId]/creatives": [99,[5,26]],
		"/(private)/offers/[offerId]/general": [100,[5,26]],
		"/(private)/offers/[offerId]/integrations": [101,[5,26]],
		"/(private)/offers/[offerId]/integrations/[trackingMethod]": [102,[5,26]],
		"/(private)/offers/[offerId]/ip-whitelisting": [103,[5,26]],
		"/(private)/offers/[offerId]/landing-pages": [104,[5,26]],
		"/(private)/offers/[offerId]/redirect-domain": [105,[5,26]],
		"/(private)/offers/[offerId]/revenue-payouts": [106,[5,26]],
		"/(private)/offers/[offerId]/smart-links": [107,[5,26,28]],
		"/(private)/offers/[offerId]/smart-links/create": [109,[5,26,28]],
		"/(private)/offers/[offerId]/smart-links/get-link/[smartLinkId]": [110,[5,26,28]],
		"/(private)/offers/[offerId]/smart-links/[smartLinkId]": [108,[5,26,28]],
		"/(private)/onboarding": [132,[5]],
		"/(private)/referral-program": [133,[5]],
		"/(private)/reports/builder": [134,[5,34]],
		"/(private)/reports/click": [135,[5,34,35]],
		"/(private)/reports/click/[clickId]": [136,[5,34,35]],
		"/(private)/reports/conversion": [137,[5,34,36]],
		"/(private)/reports/conversion/import": [139,[5,34,36]],
		"/(private)/reports/conversion/import/new": [140,[5,34,36]],
		"/(private)/reports/conversion/import/update": [141,[5,34,36]],
		"/(private)/reports/conversion/[clickId]": [138,[5,34,36]],
		"/(private)/reports/coupon-redemption": [142,[5,34,37]],
		"/(private)/reports/coupon-redemption/[cid]": [143,[5,34,37]],
		"/(private)/reports/daily-overview": [144,[5,34]],
		"/(private)/reports/mlm-commission": [145,[5,34,38]],
		"/(private)/reports/mlm-commission/[clickId]": [146,[5,34,38]],
		"/(private)/reports/overview": [147,[5,34]],
		"/(private)/settings": [148,[5,39]],
		"/(private)/settings/advertiser-payment-method": [150,[5,39]],
		"/(private)/settings/affiliate-postbacks": [151,[5,39]],
		"/(private)/settings/affiliate-registration-form": [152,[5,39]],
		"/(private)/settings/affiliate-tracking-links": [153,[5,39]],
		"/(private)/settings/click-tracking": [154,[5,39]],
		"/(private)/settings/company-details": [155,[5,39]],
		"/(private)/settings/conversion-types": [156,[5,39]],
		"/(private)/settings/currencies": [157,[5,39]],
		"/(private)/settings/custom-menu": [158,[5,39]],
		"/(private)/settings/dashboard-and-reports": [159,[5,39]],
		"/(private)/settings/data-visibility": [160,[5,39]],
		"/(private)/settings/email-customization": [161,[5,39,40]],
		"/(private)/settings/email-customization/[emailType]": [162,[5,39,40]],
		"/(private)/settings/fraud-prevention": [163,[5,39]],
		"/(private)/settings/mlm": [164,[5,39]],
		"/(private)/settings/payment-methods": [165,[5,39]],
		"/(private)/settings/payout-automation": [166,[5,39]],
		"/(private)/settings/payout-automation/submit-authorization-code": [167,[5,39]],
		"/(private)/settings/personal-access-tokens": [168,[5,39]],
		"/(private)/settings/quality-control": [169,[5,39]],
		"/(private)/settings/redirect-domains": [170,[5,39]],
		"/(private)/settings/shopify-integration": [171,[5,39]],
		"/(private)/settings/smart-links": [172,[5,39]],
		"/(private)/settings/terms": [173,[5,39]],
		"/(private)/settings/tiers": [174,[5,39,41]],
		"/(private)/settings/tiers/automations": [177,[5,39,41]],
		"/(private)/settings/tiers/create": [178,[5,39,41]],
		"/(private)/settings/tiers/[tierId]/automation": [175,[5,39,41]],
		"/(private)/settings/tiers/[tierId]/edit": [176,[5,39,41]],
		"/(private)/settings/tracking-api-authorization": [179,[5,39]],
		"/(private)/settings/users": [180,[5,39]],
		"/(private)/settings/[id]": [149,[5,39]],
		"/(auth)/sign-up": [48,[3]],
		"/(private)/sub-affiliates": [181,[5]],
		"/(public)/terms-conditions": [189,[45]],
		"/(private)/trackdesk-academy": [184,[5]],
		"/(private)/trackdesk-academy/chapter/[chapterId]": [185,[5]],
		"/(private)/trackdesk-academy/section/[sectionId]": [186,[5]],
		"/(private)/tracking-blocking": [187,[5,43]],
		"/(public)/tracking-guide": [190,[45]],
		"/(public)/tracking-guide/[trackingMethod]": [191,[45]],
		"/(private)/track/postbacks-templates": [182,[5,42]],
		"/(private)/track/postbacks-templates/[postbackTemplateId]": [183,[5,42]],
		"/(private)/webhooks": [188,[5,44]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';